<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("STORES") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <router-link
            to="add"
            class="btn btn-light-success font-weight-bolder mr-2"
            ><i class="flaticon2-plus"></i> {{ $t("ADD_NEW") }}</router-link
          >
        </div>
      </template>
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col>
              <FormSelect
                id="store-city-input"
                type="select"
                name="cities"
                :options="cities"
                clearable="true"
                v-model="filters.cityId"
                :i18n="{ label: 'CITY' }"
              ></FormSelect>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :headers="tableHeaders"
          :items="items"
          :options.sync="options"
          :items-per-page="totalItems"
          :server-items-length="totalItems"
          :loading="isLoadingStore"
          loading-text="Loading... Please wait"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: rowsPerPageItems,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right'
          }"
          @update:options="getDataFromApi()"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <router-link
              v-if="checkIfUserHasPermission(['stores-update'])"
              :to="{ name: 'edit-store', params: { id: item.id } }"
            >
              {{ item.name }}</router-link
            >
            <template v-else>{{ item.name }}</template>
          </template>

          <template v-slot:item.code="{ item }">
            <template>{{ item.code }}</template>
          </template>

          <template v-slot:item.city="{ item }">
            <template>{{ item.city.name }}</template>
          </template>

          <template v-slot:item.active="{ item }">
            <v-chip :color="getColor(item.active)" small dark pill>
              <v-avatar left>
                <v-icon>{{ iconIsActive(item.active) }}</v-icon>
              </v-avatar>
              {{ textIsActive(item.active) }}</v-chip
            >
          </template>
          <template v-slot:item.isWarehouse="{ item }">
            {{ item.isWarehouse ? "Склад" : "Ресторант" }}
          </template>
          <template v-slot:item.actions="{ item }">
            <ActionButton
              :to="{ name: 'edit-store', params: { id: item.id } }"
              :permissions="['stores-update']"
              :label="$t('EDIT')"
              :icon="'mdi-pencil'"
            />
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <template v-slot:footer></template>
    </b-card>
  </div>
</template>

<script>
//General
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import ActionButton from "@/core/components/table/ActionButton.vue";

//Store Module
import { FETCH_STORES } from "@/modules/store/store/store.module";

//City Module
import { FETCH_CITIES } from "@/modules/city/store/city.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  mixins: [permissionMixin],
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      cities: [],
      citiesParams: {
        page: 1,
        itemsPerPage: 0,
        query: ""
      },
      items: [],
      totalItems: 100,
      searchQuery: "",
      filters: {
        cityId: null
      },
      options: {
        descending: true,
        page: 1,
        itemsPerPage: 100,
        totalItems: 100
      },
      rowsPerPageItems: [100, 10, 50],
      columns: {},
      cancelSource: null,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "Name", value: "name" },
        { text: "Store Code", value: "code" },
        { text: "ECHO ID", value: "echoId" },
        { text: "City", value: "city" },
        { text: "Active", value: "active" },
        { text: "Store", value: "isWarehouse" },
        {
          align: "right",
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  components: {
    FormSelect,
    SearchTools,
    ActionButton
  },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stores", route: { name: "list-stores" } },
      { title: "Stores List" }
    ]);
    vm.getCities();
  },
  computed: {
    ...mapGetters(["getStores", "isLoadingStore"]),
    params() {
      return {
        ...this.options,
        query: this.searchQuery,
        cityId: this.filters.cityId
      };
    },
    tableHeaders() {
      let vm = this;

      return vm.headers;
    }
  },
  methods: {
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = vm.transformUrlParams(params);
      // console.log(apiParams);
      this.$store
        .dispatch(FETCH_STORES, apiParams)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;

            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
          });
        })
        .catch(response => {
          console.log(response);
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-stores" });
          }
        });
    },
    getCities(search, loading = function() {}) {
      let vm = this;
      let params = this.citiesParams;
      let apiParams = vm.transformUrlParams(params);
      this.$store
        .dispatch(FETCH_CITIES, apiParams)
        .then(data => {
          vm.cities = data.data.items;
          vm.totalCities = data.data.totalItemsCount;
        })
        .catch(response => {
          console.log(response);
        })
        .finally(() => {
          loading(false);
        });
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getColor(active) {
      if (active == false || active == 0) return "red";
      else return "primary";
    },
    textIsActive(active) {
      if (active == false || active == 0) return "Not Active";
      else return "Active";
    },
    iconIsActive(active) {
      if (active == false || active == 0) return "mdi-close-circle-outline";
      else return "mdi-checkbox-marked-circle-outline";
    }
  }
};
</script>
